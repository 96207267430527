import React from "react";

import './Video.css';
import YoutubeEmbed from "./YoutubeEmbed.tsx";

function Video(){
    return (
        <div className="Video">
            <h4 className="video-heading">Projekt do předmětu Filmová tvorba na Janáčkově akademii můzických umění (2023).</h4>
            <YoutubeEmbed embedId="4HATR9TAmvM?si=olmGg8KYkeFlMBuW" />
        </div>
    )
} 

export default Video;


